
////////////////////////// MATERIAL OVERRIDES ////////////////////////////////

// No enormous tabs

.mat-tab-label.mat-tab-label {
  min-width: fit-content;
  opacity: 0.7; // default is 0.6, contrast is too low for WCAG AAA.
}

// This one confuses me. In Material 16+ the icon is not centered in icon buttons.
// Simply making it display flex fixes it.
// .mdc-icon-button.mdc-icon-button {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   .mdc-button__label {
//     display: flex;
//   }
// }

// Tweak the menu item theme for focus style

a, button {
  &.mat-menu-item {
      //outline: 2px solid #2b2d33;
      background: #191b20;
  }

  &.mat-menu-item:hover:not([disabled]), &.mat-menu-item.cdk-program-focused:not([disabled]), .mat-menu-item.cdk-keyboard-focused:not([disabled]), .mat-menu-item-highlighted:not([disabled]) {
    background: rgba(255, 255, 255, 0.04);
  }
}

// This one allows for multiple icon buttons within matSuffix.
// not sure why the default is display: block (which causes the
// icons to stack vertically)

app-root {
    .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
        display: inline-block;
    }
}

// Technically this could be done in the typography config but we'd have to define
// our own font size and other things -- this just overrides the insane default
// letter spacing
.mdc-button.mdc-button {
  letter-spacing: normal;
}

// disabled mat-button links shouldn't be clickable

.mat-mdc-tab-body-content {
  padding: 1em;
}

.mat-mdc-tab-body-wrapper {
  height: 100%;
}

// button label doesn't stretch to end of the button in some cases
// NOTE: disabled as it broke Banta 5.6's full width paging buttons... scream test
// .mdc-button__label {
//   flex-grow: 1;
// }

mat-checkbox.wrappable {
  label.mat-checkbox-layout {
    white-space: initial;
    .mat-checkbox-inner-container {
      margin: 0 8px 0 0;
      position: relative;
      top: 2px;
    }

    .mat-checkbox-label {
      line-height: initial;
    }
  }
}

[mat-tab-nav-bar].stretch-tabs {
  .mat-tab-link {
    flex-grow: 1;
  }
}

mat-tab-group.stretch-tabs {
  .mat-tab-label {
    flex-grow: 1;
  }
}

.mdc-snackbar.error .mdc-snackbar__surface {
  background: #881b1b;
  --mdc-snackbar-supporting-text-color: white;
}

mat-icon {
  flex-shrink: 0;
}

// Overcome devtools high z-index by being just a little bit higher.

.cdk-overlay-container.cdk-overlay-container {
  z-index: 10003;
}
