// This is the start of a bootstrap replacement
// for the purposes of trimming the weight of bootstrap
// and reducing dependencies. It is not used yet because
// we depend on the row/col-* layouting and that is not covered
// here. Haven't decided yet if bringing that functionality into
// here is better or if eliminating usage of row/col-* would be
// preferable.

body {
    // font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
    // font-size: 1rem;
    // font-weight: 400;
    line-height: 1.5;
    // color: #292b2c;
    // background-color: #fff;
}

[role=button], a, area, button, input, label, select, summary, textarea {
    touch-action: manipulation;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}

h1 {
    font-size: 2em;
    margin: .67em 0;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

img {
    vertical-align: middle;
}

label {
    display: inline-block;

    // This messes with a lot of stuff. I'd rather just fix the places where this is needed than keep this global rule
    //margin-bottom: .5rem;
}

.form-group {
    margin-bottom: 1rem;
}

.form-control {
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

button, input, optgroup, select, textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,input,select,textarea {
    line-height: inherit
}

[type=checkbox], [type=radio] {
    box-sizing: border-box;
    padding: 0;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.btn.disabled, .btn:disabled {
    cursor: not-allowed;
    opacity: .65;
}

.badge {
  display: inline-block;
  font-size: 60%;
  font-weight: bold;
  letter-spacing: 1px;
}