.alert {
  $padding: 1em;

  // Using inline-block here specifically to avoid margin collapse when
  // the alert is the last item in a container which doesn't have margin of
  // its own.
  display: inline-block;
  width: calc(100% - $padding);

  padding: $padding;
  margin: 0.5em 0;
  border-radius: 5px;
  border: 1px solid transparent;
  &::before, &::after{
    content: ' ';
    display: table;
  }
}

.alert.with-icon {
  display: flex;
  align-items: center;
  gap: 1em;

  &.long {
    align-items: flex-start;
  }

  &>mat-icon {
    $size: 64px;
    width: $size;
    height: $size;
    font-size: $size;
    flex-shrink: 0;
  }
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;

  code {
    color: inherit;
  }
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;

  --mdc-text-button-label-text-color: black;

  &.muted {
    color: #d1ecf1;
    background-color: #06373f;
    border-color: #08333a;
  }
}

.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.alert-slidable {
  overflow-y: hidden;
  max-height: 0;
  border-width: 0;
  padding: 0 1.25rem;

  transition: max-height 0.4s ease-in, padding 0.4s ease-in;

  &.visible {
    max-height: 200px;
    padding: 0.75rem 1.25rem;
    border-width: 1px;
  }
}


.alert-info {
  a {
    color: #536cc6;
  }
}

.alert-warning {
  color: #414141;
  background-color: #d3d96f;
  border: none;

  .mat-mdc-button:not(:disabled) {
    color: black;
  }
  .mat-mdc-button:disabled {
    color: rgba(0,0,0,0.5);
  }
  code {
    color: #414141;
    border-radius: 2px;
    background-color: #faffaa;
    padding: 2px 4px;
    border: 1px solid #2b2b21;
  }

  a {
    color: #a419ba;
  }
}

.alert-debug {
  background-color: #223d2f;
  color: #c2ffed;

  a {
    color: #62ffb4;
  }
}

.alert-membership {
  color: black;
  background-color: $tyt-plus-color;
  border: none;

  a {
      color: rgb(15, 45, 123);

      &:hover {
          color: rgb(15, 45, 123);
      }
  }
}

.alert h1 {
  color: inherit;
}