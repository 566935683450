@use "sass:math";

$bg-color: #171921;
$bg-color-2: #21232b;
$highlight-color: #373c46;
$tyt-font: sans-serif;
$tyt-plus-color: #ECC036;
$tyt-account-color: #efb42c;

/**
 * Maximum width of the site. Beyond this, the content and nav elements
 * remains at 1700px in the center of the document
 */
$max-width: 1700px;

$text-bright: white;
$text-mid: #999;
$text-dim: #a8a6a6; // A11y/WCAG sensitive: contrast ratio (7.09 >= 7)

$menu-breakpoint: 1250px;
$large-menu-height: 88px;
$small-menu-height: 64px;
$site-wide-mobile-breakpoint: 450px;
$phone-nav-breakpoint: 620px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 950px,
    lg: 1280px,
    xl: 2000px
);

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before{
        display: block;
        content: " ";
        width: 100%;
        padding-top: math.div($height, $width) * 100%;
    }

    > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

// BRAND COLORS

$facebook: rgb(59, 66, 140); //facebook
$twitter: rgb(85, 161, 237); //twitter
$googleplus: rgb(222, 50, 32); //google+
$linkedin: rgb(0, 96, 168); //linkedin
$youtube: rgb(206, 1, 2); //youtube
$instagram: rgb(106, 65, 58); //instagram
$pinterest: rgb(189, 8, 28); //pinterest
$tumblr: rgb(60, 108, 174); //tumblr
$android: rgb(157, 199, 24); //android green
$ios: rgb(29, 98, 240); //ios blue
$rss: rgb(255, 161, 17); //rss color
$subscribe: rgb(88, 171, 0); //green color


$two-tone-default-x: 26px;
$two-tone-default-y: 14px;

@mixin two-tone-1 {
  padding: $two-tone-default-y $two-tone-default-x $two-tone-default-y
    $two-tone-default-x;
  background: $bg-color;
}

@mixin two-tone-2 {
  padding: $two-tone-default-y $two-tone-default-x $two-tone-default-y
    $two-tone-default-x;
  background: $highlight-color;
}

@mixin scrollbarColor($track, $thumb, $button, $arrow) {
  scrollbar-color: $track $thumb;
  scrollbar-arrow-color: $arrow;

  &::-webkit-scrollbar { // the middle background
      width: 8px;
      background-color: $track;
  }

  &::-webkit-scrollbar-button { // the top/bottom
      background-color: $button;
      color: $arrow;
      width: 0px;
      height: 0px;
  }
  &::-webkit-scrollbar-thumb { // the piece that moves
      background-color: $thumb;
      color: $arrow;
  }
}