@use 'styles/colors' as colors;

.mdc-button {
  &.neutral {
    color: colors.$neutral;
    background-color: colors.$background-highlight;
  }

  &.sharp {
    border-radius: 0;
  }
}