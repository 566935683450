
@keyframes placeholderMarquee {
  0% {
    left: 0%;
  }

  100% {
    left: -100%;
  }
}

span.placeholder {
  background-color: rgba(255, 255, 255, 0.1);
  width: 10em;
  display: inline-block;
  height: 0.9em;
  overflow: hidden;
  margin-right: 1em;

  &:after {
    display: block;
    content: ' ';
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(0,0,0,0.3) 0%,
      rgba(0,0,0,0) 25%,
      rgba(0,0,0,0.3) 50%,
      rgba(0,0,0,0) 75%,
      rgba(0,0,0,0.3) 100%
    );

    position: relative;
    animation-name: placeholderMarquee;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
}

picture.placeholder {
  overflow: hidden;

  &:after {
    display: block;
    content: ' ';
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(0,0,0,0.3) 0%,
      rgba(0,0,0,0) 25%,
      rgba(0,0,0,0.3) 50%,
      rgba(0,0,0,0) 75%,
      rgba(0,0,0,0.3) 100%
    );

    position: relative;
    animation-name: placeholderMarquee;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }

  img {
    display: none;
  }
}