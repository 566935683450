// Dialogs: Support for animating the page content down while dialog
// is appearing.

app-root {
  > main, tyt-nav nav#menu {
      position: relative;
      top: 0;
      transition: 400ms top ease-in-out, 400ms opacity ease-in-out;
      opacity: 1;
  }
}

app-root.dialog-visible {
  & main, & tyt-nav nav#menu {
      top: 100px;
      opacity: 0;
  }
}