@use "sass:color";

$bg-color: #171921;
$bg-color-2: #21232b;
$highlight: #373c46;
$tyt-font: sans-serif;
$tyt-plus: #ECC036;
$tyt-account: #efb42c;
$tyt-donation: #003FE7;
$actblue-brand: #009cde;
$tyt-community: lime;

$translucent: color.change(#ccd1dc, $alpha: .1);
$translucent-background: color.change(#21232a, $alpha: .5);
$translucent2: color.change(#75767e, $alpha: .1);
$translucent-background2: color.change(#606770, $alpha: .08);


$neutral: white;
$neutral2: #cbcbcb;
$neutral3: #a8a8a8;
$neutral4: #dfdfdf;
$neutral5: #e0e0e0;
$neutral-secondary: #c8c8c8;

$accent: #003fe7;
$accent2: #efb42c;
$plus: $accent2;

$live: #d92026;
$username-text: #efb42c;
$topic-text: #3dff3d;

$orange: #ff8533;
$green: #89ed5b;
$red: #e22d3c;

$link1: #89ed5b;
$link2: #55acee;
$link3: #91aaed;

$gray: #9e9e9e;
$gray2: #c6cacc;
$gray3: #606770;
$gray-background: #393d46;
$gray-background2: #151820;

$background: #171921;
$background2: #1e1f24;
$background3: #1d222e;
$background4: #1b1e25;
$background5: #343740;
$background-highlight: #2a2e3c;
$neutral-background: #84899c;
$neutral-background2: #1e1e1e;


$dark-background: #191b22;
$dark-background2: #1e1f24;
$dark-background3: #23262b;


$background-progressbar: #2e3243;

$nav-text: #808080;
$nav-text-hover: $neutral;
