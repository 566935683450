
.field-set {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.field-row { // originally added for the block editor component system
  display: flex;
  gap: 1em;
  align-items: baseline;
  @media (max-width: 599px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.button-row { // originally added for the block editor component system
  display: flex;
  gap: 0;
  align-items: center;
  flex-wrap: wrap;
}

.form-actions-row {
  display: flex;
  gap: 1em;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.field-header {
  font-size: 20px;
  margin-top: 1em;
}

div.explanation {
  margin-left: 4em;
  font-style: oblique;
  margin-bottom: 2em;
  color: #777;
}

em.explanation {
  color: #777;
  display: block;
  margin-bottom: 1em;
}