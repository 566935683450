
 /**
  * You can add global styles to this file, and also import other style files
  * This file will be defer-loaded, so anything here will not show up immediately on first
  * render. If you need the styles to be immediately visible, use critical-styles.scss instead,
  * but keep those to an absolute minimum.
  */
@import './styles/material-theme.scss';

// REGARDING BOOTSTRAP
// We are removing Bootstrap from this application. It has been disabled and a lot of places have been
// checked and tweaked as necessary. If something appears to have broken recently, try uncommenting
// actual bootstrap below and comment out the bootstrap-lite.scss. If that fixes it, then we need to
// either update the markup to not rely on bootstrap, or update bootstrap-lite to fix it.
//@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./styles/bootstrap-lite.scss";
@import "./styles/form-styles.scss";
@import "./styles/critical-styles.scss";
@import "./styles/material-icons.scss";
@import "./styles/operation-hope.scss";
@import "./styles/alerts.scss";
@import "./styles/buttons.scss";
@import "./styles/placeholders.scss";
@import "./lib";

:root {
  --article-view-background: #{$bg-color-2};
}

pre {
    background: $bg-color-2;
    color: $text-mid;
    padding: 1em;

    &.wrapped {
        white-space: pre-wrap;
    }
}

@import "styles/dialog-animations.scss";

* {
  @include scrollbarColor(#13151c, #33374a, $bg-color-2, white);
}

/*
 * HACK
 * This is a hack for the Video Speed Controller Chrome extension which,
 * without these rules, breaks our page rendering.
 */
.vsc-controller {
    position: absolute;
    z-index: 99999 !important;
    pointer-events: initial;
}

label.key {
    display: inline-block;
    min-width: 8em;
    color: #777;
}

.anchor {
    position: relative;
    height: 1px;
    overflow: hidden;
    top: -5em;
    visibility: hidden;
    display: block;
}

.alert {
  text-shadow: none;
}

// Main Text Styles
$text-light-one: rgb(250, 250, 250); //white

//Background colors - main style
$dark-two: rgb(22, 22, 22); //lighter black
$dark-two-fade: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, $dark-two 100%); //dark fade gradient
$dark-three: rgb(80, 80, 80); //dark grey

$light-three: rgb(240, 240, 240); //lighter grey
$light-three-fade: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, $light-three 100%); //light fade gradient

$primary-color: #284e78; //red
$secondary-color: rgb(6, 148, 191); //blue

a.btn, a.btn:hover, a.btn:focus { color: $text-bright; }
.btn.btn.btn-primary { background-color: $primary-color; }
.btn-secondary { background-color: #555; }

// PLACEHOLDERS ---------------------------------------------- //
/* WebKit, Blink, Edge */ ::-webkit-input-placeholder { color: $text-mid; }
/* Mozilla Firefox 4 to 18 */ :-moz-placeholder { color: $text-mid; opacity:  1; }
/* Mozilla Firefox 19+ */ ::-moz-placeholder { color: $text-mid; opacity:  1; }
/* Microsoft Edge */ ::-ms-input-placeholder { color: $text-mid; }

h1 {
  margin: 1em 0 0.25em 0;
}

time { color: $text-dim; }
hr {
  border: 1px solid $dark-three;
}
select, input, textarea, .input-group-addon {
    background-color: $light-three;
}
input.error, select.error {
    border-color: $primary-color !important;
}
a.btn.btn {
    background-color: black;
    color: white;
}
a.btn, a.btn:hover, a.btn:focus {
    color: $text-light-one;
}

.cast-playback-element {
    height: 100%;
    background: black;
    background-image: url(/assets/offer_bg_short.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    .underlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: rgba($bg-color-2, 0.9);
        backdrop-filter: blur(8px);
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 6em;
    }
}

.pip .cast-playback-element {
  .overlay {
    padding-bottom: 0;

    .text {
      display: none;
    }
  }
}


// .btn-2.btn-2 {
//     display: inline-block;
//     background: white;
//     color: #333;
//     max-width: 100%;
//     width: 20em;

//     @media (max-width: 620px) {
//         width: 100%;
//     }

//     padding: 1em;
//     margin: 0.5em;
//     text-align: center;

//     &:focus {
//         outline: 4px solid #0066ff;
//     }

//     &.btn-compact {
//         min-width: 7em;
//         width: auto;
//         padding: 0.4em 1em;
//     }

//     &.btn-secondary {
//         background-color: transparent;
//         border: 1px solid white;
//         color: white;
//     }

//     &.btn-icon {
//         width: auto;
//         min-width: initial;
//     }

//     &.btn-cancel {
//         background: black;
//         color: white;
//     }
// }

.info-note {
    background: #171921;
    border-radius: 5px;
    color: white;
    border: 1px solid black;
    padding: 1em;
    margin-top: 1em;
    position: relative;

    a.close {
        position: absolute;
        right: 15px;
        top: 10px;
        color: white;
    }

    h1 {
        color: inherit;
        padding-left: 0;
    }
}


@for $i from 1 through 8 {
    [data-shutter-order="#{$i}"] {
        transition-delay: calc(200ms * #{$i}) !important;
    }
}

@media print {
    html {
        app-root dialog#dialog-host {
            display: none !important;
        }

        body {
            background: white !important;
        }

        app-root {
            background: white !important;
        }

        tyt-nav {
            display: none !important;
        }

        #main-footer {
            display: none !important;
        }

        .comments-container {
            display: none !important;
        }

        #drop-footer {
            background: white !important;
            color: black !important;

            img {
                filter: invert(1) !important;
            }

            .links {
                display: none !important;
            }
        }

        .devtools {
            display: none !important;
        }
    }
}

ul.fact-list {
    display: flex;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;

    li {
        list-style-type: none;
        border-left: 1px solid white;
        padding-left: 0.5em;
        margin-left: 0.5em;

        &:first-of-type {
            border-color: transparent;
            margin-left: 0;
            padding-left: 0;
        }
    }
}

/**
 * YouTube embeds are a PitA- they have width="560" or so which
 * messes up layouts everywhere. This rule enforces that iframes must
 * always be no wider than the width of the page.
 */
iframe {
    max-width: 100%;
}

.cdk-overlay-container.cdk-overlay-container {
    z-index: 9999;
}

// Supports the .mobile-nav element by ensuring there is enough space for it to float over
// without occluding page elements.
@media (max-width: 499px) {
    app-root {
        margin-bottom: 5em;
    }
}

blockquote {
    border-left: 4px solid #485069;
    padding-left: 0.4em;
}

a.disabled {
    pointer-events: none;
}

a.has-url-text {
  max-width: min(100%, 20em);
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  vertical-align: top;
}

.capitalize {
	text-transform: capitalize;
}

.show-on-focus {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  background-color: #171921;
  color: white;
  padding: 1em;
  border: 2px solid #4f5469;

  &:hover {
    border-color: #476aff;
  }
}

.show-on-focus:focus {
  z-index: 9999;
  width: auto;
  height: auto;
  clip: auto;
}

@import './styles/material-overrides.scss';

[role="img"] {
    background-size: cover;
}

code {
  color: #48b757;
}

div.property {
  display: flex;
  align-items: flex-start;

  label {
    min-width: 6em;
    color: #777;
  }

  &.long {
    label {
      min-width: 12em;
    }
  }
}

/** Applied to all elements that are populated with CMS text content.
    Warning: Includes block and inline elements!
  */
.cms-content {
  word-wrap: break-word;
}

.float-clear {
  clear: both;
}

mat-icon.inline {
  vertical-align: middle;
}

// avoid unactivated banta autocomplete widgets from affecting
// the scroll height of the document (so that iframe resizer can
// work stably)
app-root > .autocomplete {
  top: 0;
  left: 0;
}

.tag {
  font-size: 80%;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  padding: 2px 5px;
  background: #444;
  color: white;
  border-radius: 5px;

  mat-icon {
    vertical-align: middle;
    $size: 1em;
    font-size: $size;
    width: $size;
    height: $size;
  }
}

app-root .banta-top-sticky.banta-top-sticky {
  top: calc(var(--tyt-page-top) + 1em);
}