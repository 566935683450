@use '@angular/material' as mat;
@use 'sass:map';

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);

$slate-palette: (
  50: #edeff3,
  100: #d3d8e0,
  200: #b7bfcb,
  300: #9aa5b5,
  400: #8491a4,
  500: #6f7e94,
  600: #616f82,
  700: #505b6b,
  800: #2f353e,
  900: #1c2127,
  A100: #d3d8e0,
  A200: #b7bfcb,
  A400: #8491a4,
  A700: #505b6b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$tyt-primary: mat.m2-define-palette($slate-palette, 300);
$tyt-accent:  mat.m2-define-palette(mat.$m2-indigo-palette, 300, 200, 400);
$tyt-warn:    mat.m2-define-palette(mat.$m2-red-palette);

$tyt-theme: mat.m2-define-dark-theme((
  color: (
    primary: $tyt-primary,
    accent: $tyt-accent,
    warn: $tyt-warn
  ),
  typography: mat.m2-define-typography-config(
    $font-family: sans-serif
  ),
  density: 0,
));

// Modify the background color (https://stackoverflow.com/a/70743945/1995204)
$backgroundColor: #232633;
$color: map.get($tyt-theme, "color");
$colorBackground: map.get($color, "background");
$colorBackground: map.set($colorBackground, "background", $backgroundColor);
$colorBackground: map.set($colorBackground, "card", $backgroundColor);
$colorBackground: map.set($colorBackground, "dialog", $backgroundColor);
$colorBackground: map.set($colorBackground, "raised-button", $backgroundColor);
$colorBackground: map.set($colorBackground, "selected-disabled-button", $backgroundColor);
$color: map.set($color, "background", $colorBackground);
$tyt-theme: map.set($tyt-theme, "color", $color);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tyt-theme);

html {
  --content-background: #171921;
  --content-foreground: white;
}

.mat-light-theme {
  --content-background: white;
  --content-foreground: #111;

  $light-theme-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
  $light-theme-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
  $light-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);
  $light-theme: mat.m2-define-light-theme((
      color: (
          primary: $light-theme-primary,
          accent: $light-theme-accent,
          warn: $light-theme-warn
      ),
      typography: mat.m2-define-typography-config(
        $font-family: sans-serif
      ),
      density: 0,
  ));
  @include mat.all-component-colors($light-theme);
}