@import '../lib.scss';

html {
    background-color: $bg-color;
    //overflow-x: hidden;

    &:has(app-root.transparent-bg) {
      background-color: transparent;
    }

    &.chrome {
        overflow: hidden;
    }
}

* {
    box-sizing: border-box;
}

main > * {
    width: $max-width;
    max-width: 100%;
    margin: 0 auto;
    display: block;
}

@media (max-width: $max-width) {
    main > * {
        width: auto;
        padding: 0 5em;
    }
}

@media (max-width: 750px) {
    main > * {
        width: auto;
        padding: 0;
    }
}

summary {
    display: block;
}

body {
    font-family: sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $bg-color;
    color: $text-bright;
    overflow-x: clip;

    &:has(app-root.transparent-bg) {
      background-color: transparent;
    }
}

input.error, select.error {
    border: 3px solid !important;
}

.v-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

dialog .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    // TODO: Potential regression
    // a, button {
    //     margin: 0.5em 1em;
    // }
}

dialog div.standard-page-layout {

    h1 {
        text-align: center;
    }

    p {
        text-align: center;
        margin: 1em 0;

        &.welcome {
            font-weight: bold;
            font-size: 1.25em;
        }

        &.primary {
            font-weight: bold;
        }

        &.main {
            text-align: left;
        }

    }

    .options {
        font-style: italic;
        font-size: 70%;
    }

    .input-group-addon, input[type=text], input[type=password], textarea {
        border: none;
    }

    textarea {
        padding: 0.5em;
    }
}

.error-info {
    background: rgba(243, 117, 117, 0.85);
    box-shadow: 0px 0px 10px rgba(0,0,0,0.7);
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
    padding: 0.4em 1em;
}

div.content-block {

    p.first {
        font-size: 125%;
    }

    img {
        max-width: 100%;
    }

    blockquote {
        border-left: 8px solid rgb(204, 204, 204);
        padding-left: 1em;
        font-size: 125%;
    }
}

router-outlet {
    display: none !important;
}

main.chrome-flex > * {
    display: flex;
    flex-direction: column;
    padding: 0;
}

section.rider {
    border-bottom: 2px solid rgb(201, 201, 202);
    background: rgb(255, 255, 255);

    & > h1 {
        //margin: 0.25em;
    }

    & .rider-flush {
        margin-left: 3.5em;
    }

}

html.menu-open {
    overflow-y: hidden;

    body {
        overflow-y: hidden;
    }
}

.thumbnail {
    display: block;
    height: calc(11.45vw - .5em);
    width: calc(25% - 1.1em);
    background: rgb(12, 11, 11);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    flex-grow: 0;
    opacity: 1;
}

section.slider {
    position: relative;
    padding: 0;
    height: 50vh;
    min-height: 17em;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

dialog {
    .row {
        margin: 0;
    }

	a.dialog-close.dialog-close {
		position: absolute;
		display: inline-block;
		width: auto;
		right: 0;
		top: 0;
		padding: 1em 1.5em;
    z-index: 1000;
	}

	& > .dialog-content {
		display: block;
		max-width: 100%;
		width: 28em;
		padding: 0 1.9em;
	}

	& > * > div.standard-page-layout {
		max-height: calc(100vh - 3em);
		overflow-y: auto;
        padding: 0 3px;

        &.no-badge {
            padding: 0 3px 0 3px;
        }
	}

    @media (max-width: 750px) {
      max-width: 100%;
	    max-height: 100vh;

        &.visible {
            top: 0 !important;
        }

        .dialog-content {
            width: 100%;

            & > div.standard-page-layout {
                padding: 0.25em;
                max-height: 100vh;
            }
        }

        .chrome-box {
            display: none;
        }
    }

	@media (max-width: 450px) {
		padding: 0.5em;

		.dialog-content {
			padding: 0 0.5em;
		}

	}

	@media (max-width: 320px) {
        width: 100%;
		padding: 0;

		& > * {
			padding: 0 0.5em;
		}

        .dialog-content {
            & > div.standard-page-layout {
                padding: 1em 0.1em;
            }
        }
	}
}

// SOCIAL STYLES

.social-buttons {
    display: flex;
    margin-bottom: 1em;
    flex-wrap: wrap;

    a {
        display: flex;
        margin-right: 1em;
        min-width: fit-content;

        &:hover {
            text-decoration: none;
        }

        i {
            text-align: center;
            margin-right: 0.25em;
            font-size: 1.75em;
        }
    }
}

.twitter {
    word-break: break-word;

    div {

        &:first-child {
            display: flex;
            flex-direction: row;
            margin-bottom: 0.25em;
            align-items: center;

            img {
                width: 3em;
                height: 3em;
                background: rgb(12, 11, 11);
                border-radius: 5px;
                flex-grow: 0;
                margin-right: 8px;
                padding: 0.25em;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

section {
    padding: 0 5vw;

    &:last-child {
        padding-bottom: 6em;
    }

    .text-fade:before {
        position: absolute;
        display: flex;
        bottom: 0;
        right: 0;
        content: "";
        height: 50%;
        width: 100%;
    }

    a.play-video {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &.small {

            i {
                font-size: 2.25em;
            }
        }

        &:hover, &:focus {
            text-decoration: none;
        }

        i {
            width: 2em;
            height: 2em;
            font-size: 3.25em;
            text-align: center;
            border-radius: 3px;
            box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.36);
            padding: 0.5em;
        }
    }
}

.box-inner {
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
    padding: 2em;
}

.btn {
    border-radius: 0px;
    font-weight: 400;
}

.input-group-addon {
    min-width: 42px;
}

.wysiwyg {
    img {
        max-width: 100%;
    }
}

.btn {
    letter-spacing: 1px;
    border: none;
}

.btn.btn.btn-info {
    border: none;
}

input:focus, textarea:focus, select:focus, .btn:focus {
    box-shadow: none;
    outline: none;
}

main {
    //min-height: 20.77vw;

    & > div.standard-page-layout, & > * > div.standard-page-layout {
        padding: 0em 0.5em;

        h1 { font-size: 22pt; }
        h2 { font-size: 18pt; }
        h3 { font-size: 16pt; }
        h4 { font-size: 14pt; }
        h1, h2, h3 {
          font-weight: bold;
          margin: 1em 0 0.25em 0;
        }
    }
}

h1 {
    font-size: 2em;
    font-weight: 400;
}

h2 {
    font-size: 1.25em;
    font-weight: 400;
}

h3 {
    font-size: .85em;
}

div.spacer {
    flex-grow: 1;
}

a {
    transition: color .3s ease-in-out;
    text-decoration: none;
    color: #53aeff; // A11y/WCAG sensitive: Contrast ratio (7.26 >= 7)

    &:hover, &:focus {
        color: #32a0ff;
        transition: color .3s ease-in-out;
        text-decoration: none;
    }

}

@import "browser-specific.scss";